import React, {useEffect, useState} from 'react'
import homie from '../../images/thehomie.png'
import otherhomie from '../../images/otherhomie.png'
// import thome from '../../images/tyllenHome.png'
import theZone from '../../images/thozone2.gif'
import work from  '../../images/work.png'
import retrofb from '../../images/retrofb.gif'
import vocoretro from '../../images/vocoretro.png'
import boundretro from '../../images/boundretro.png'
import writingsretro from '../../images/writingsretro.png'
import podcastretro from '../../images/podcastsretro.png'
// import MediumBlog from '../MediumBlog/MediumBlog'
import axios from 'axios'



function Home() {

    const [blogs, setBlogs] = useState('')
    const [toggle, setToggle ] = useState('false') 

    const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tyllenbicakcic";

    useEffect(() => {
        axios.get(mediumURL)
        .then(info => {
            console.log(info)
            setBlogs(info)
        })
    }, [mediumURL, setBlogs])

    return (
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-none">
            <div id="nft" className="col-span-3"/>
            <div 
            className="sm:invisible col-span-3 text-right px-20 py-3 w-full font-mono text-gray-300 fixed right-0"
            style={{backgroundColor: '#262627'}}
            >
                <a href="#about" className="mr-6">gm</a>
                <a href="#work" className="mr-6">work</a>
                <a href="#writing" className="mr-6">writings</a>
                <a href="#podcast" className="mr-6">podcasts</a>
                <a href="#nft" >nft fun</a>
            </div>
            <div className="col-span-3 flex sm:flex-col-reverse">
            <div className="mt-12">
                {toggle === false ?
                    <img alt="thehomie" src={homie} className="ml-36 mt-20 h-96 sm:ml-28 sm:h-52 sm:mt-2" />
                    :
                    <img alt="otherhomie" src={otherhomie} className="ml-20 mt-20 h-96 sm:ml-20 sm:h-52 sm:mt-2" />
                }
                <button 
                className= 'font-mono p-1 bg-gray-700 text-gray-400 mt-2 ml-14 sm:-ml-5'
                onClick={() => setToggle(!toggle)}
                >
                    click for diff nft
                </button>
            </div>
            <div className=" mt-12">
                <img alt="thezone" src={theZone} className="mt-20 h-96 ml-24 sm:h-52 sm:ml-0.5 sm:mt-1"/>
            </div>
            </div>
            <div id="about"/>
            <div className="col-span-3 text-gray-300 font-mono text-left mt-20">
                <div className="px-20 sm:px-8 sm:max-w-sm">
                gm
                <br></br>
                <br></br>
                welcome to my space. my name is tyllen. stoked to have you here! since i havent made my own space in the metaverse just yet, this is where you can see a lot of what im up to :]
                <br /><br /> im a big believer in crypto and nfts. when im outside the metaverse i spend my time in the mountains. im a big basketball fan, the rockets are my fave team, so much so that <a href="https://cw39.com/cw39/whos-gonna-buy-the-houston-rockets/" className="text-blue-500" target="_blank" rel="noopener noreferrer">i tried to buy them</a>.
                <br /><br />people who inspire me are kobe bryant, the godfather, cristiano ronaldo, and my mother. 
                <br /><br />lets get after it, what better day than today to be great?
                </div>
            </div>
            <div id="work" className="col-span-3"/>
            <div className="col-span-1 text-gray-300 font-mono text-left mt-10 px-20 sm:px-4 sm:mt-8">
                <img alt="work" src={work} className="-ml-4 h-20 sm:h-12" />
            </div>
            <div className="col-span-3 px-20 font-mono text-gray-300 sm:max-w-sm">
                <div className='flex place-content-around'>
                    <div style={{textAlign:'-webkit-center'}} className='px-4'>
                        <img alt="retrofb" src={retrofb} className="h-20 sm:h-12" />
                        <div className="text-center mt-4" >
                            facebook
                        </div>
                        <div className="mt-2 text-center sm:text-xs">
                            founding member of Lasso team which became IG reels
                        </div>
                    </div>
                    <div style={{textAlign:'-webkit-center'}} className='px-4'>
                        <img alt="bound" src={boundretro} className="h-20 sm:h-12" />
                        <div className="text-center mt-4" >
                            bound
                        </div>
                        <div className="mt-2 text-center px-2 sm:text-xs ">
                            co-founded ai sales messaging tool
                        </div>
                    </div>
                    <div style={{textAlign:'-webkit-center'}} className='px-4'>
                        <img alt="voco" src={vocoretro} className="h-20 sm:h-12" />
                        <div className="text-center mt-4" >
                            voco
                        </div>
                        <div className="mt-2 text-center px-2 sm:text-xs">
                            co-founded voice app tech for seniors
                        </div>
                    </div>
                </div>
            </div>
            <div id="writing" className="col-span-3"/>
            <div className="col-span-1 text-gray-300 font-mono text-left mt-10 px-20 sm:px-4 sm:ml-1">
                <img alt="writing" src={writingsretro} className="-ml-4 h-20 sm:h-12" />
            </div>
                <div className="col-span-3 text-gray-300 font-mono text-left mt-4 px-20 sm:max-w-sm">
                    {blogs !== '' ? 
                        <div className="flex place-content-around sm:flex-col sm:-ml-10 sm:w-80">
                            <div className="px-4">
                                <a href={blogs.data.items[0].link} target="_blank" rel="noopener noreferrer">
                                    <img alt="blog1" src={blogs.data.items[0].thumbnail} className="max-h-72 sm:max-h-10"/>
                                    <br /><div className="sm:text-sm sm:-mt-4">{blogs.data.items[0].title}</div>
                                    <br /><div className="text-sm text-gray-600 sm:text-xs sm:-mt-5 sm:mb-6">{blogs.data.items[0].pubDate}</div>
                                </a>
                            </div>
                            <div className="px-4 ">
                                <a href={blogs.data.items[1].link} target="_blank" rel="noopener noreferrer">
                                    <img alt="blog2" src={blogs.data.items[1].thumbnail} className="max-h-72 sm:max-h-10"/>
                                    <br /><div className="sm:text-sm sm:-mt-4">{blogs.data.items[1].title}</div>
                                    <br /><div className="text-sm text-gray-600 sm:text-xs sm:-mt-5 sm:mb-6">{blogs.data.items[1].pubDate}</div>
                                </a>
                            </div>
                            <div className="px-4">
                                <a href={blogs.data.items[2].link} target="_blank" rel="noopener noreferrer">
                                    <img alt="thumbnail" src={blogs.data.items[2].thumbnail} className="max-h-72 sm:max-h-10"/>
                                    <br /><div className="sm:text-sm sm:-mt-4">{blogs.data.items[2].title}</div>
                                    <br /><div className="text-sm text-gray-600 sm:text-xs sm:-mt-5 sm:mb-6">{blogs.data.items[2].pubDate}</div>
                                </a>
                            </div>
                        </div>                    
                    : 
                    <div>Loading...</div>
                    }
                </div>
                <div className="col-span-3 text-gray-300 font-mono text-left mt-2 px-20 sm:px-10">
                <h1><u>List of Writings</u></h1>
                <br />
                {blogs !== '' ? 
                blogs.data.items.map((blog, index) =>{
                    return(
                        <div key={index}>
                            <a href={blog.link} target="_blank" rel="noopener noreferrer" className="sm:text-xs">{blog.title}</a>
                            <br />
                        </div>

                    )
                })
                :
                <></>
                }
                </div>
                <div id="podcast" className="col-span-3"/>
                <div className="col-span-1 text-gray-300 font-mono text-left mt-10 px-20 sm:p-4 sm:ml-2 sm:mt-4">
                    <img alt="podcast" src={podcastretro} className="-ml-4 h-20 sm:h-10" />
                </div>
                <div className="col-span-3 text-gray-300 font-mono text-left mt-4 px-20 mb-20 sm:max-w-sm">
                <iframe title="spotify" src="https://open.spotify.com/embed/show/0Y1ZfVmQbinB1wVCw4iCH4" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
        </div>
    )
}

export default Home
